
import {
  ArchivedDataUploadPage,
  } from 'src/views/archivedDataUpload'
  import pathMap from 'src/paths/pathMap'
  
  class ArchivedDataUploadRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.ARCHIVED_DATA_UPLOAD,
          component: ArchivedDataUploadPage
        }
      ]
    }
  }
  
  export default ArchivedDataUploadRoute
  