
import {
    DataRestorePage,
  } from 'src/views/dataRestore'
  import pathMap from 'src/paths/pathMap'
  
  class DataRestoreRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.DATA_RESTORE,
          component: DataRestorePage
        }
      ]
    }
  }
  
  export default DataRestoreRoute
  